import {
  modalController,
} from '@ionic/vue';
import { auth } from '@/auth';
import store from '@/store';
import CartService from '@/services/Cart';
import LoginModal from '@/components/LoginModal.vue';

export default {
  computed: {
    user() {
      return store.auth;
    },
  },
  methods: {
    /**
     * Logs out the current user.
     */
    async logout() {
      await auth.clearStorage();
      CartService.empty();
    },
    /**
     * Open login modal.
     *
     * @param {Function} onLogin
     */
    async openLogin(onLogin) {
      const modal = await modalController
        .create({
          component: LoginModal,
          componentProps: {
            title: this.$t('account.login'),
            onLogin,
          },
        });

      // Present modal
      await modal.present();
    },
    /**
     * Check login state, fire callback when logged in.
     *
     * @param {Function} callback
     */
    async checkLogin(callback) {
      if (store.auth.id) {
        callback();
      } else {
        await this.openLogin(callback);
      }
    },
  },
};
