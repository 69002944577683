import { Storage } from '@ionic/storage/dist/esm';
import { reactive } from 'vue';
import cloneDeep from 'lodash.clonedeep';

export const storage = (new Storage()).create();
export const emptyCart = {
  order_id: null,
  initial_app_version: process.env.PACKAGE_VERSION,
  app_version: process.env.PACKAGE_VERSION,
  client: {
    salutation: null,
    customer_name: null,
    mold_number: null,
  },
  lines: [],
};

export default reactive({
  auth: {
    id: null,
    name: null,
    password: null,
    is_user: false,
    partner_id: null,
    lang: 'nl_NL',
  },
  cart: cloneDeep(emptyCart),
});
