import config from '@/config';

export default [
  {
    name: 'dashboard',
    path: '',
    component: () => import('@/views/Dashboard.vue'),
  },
  {
    name: 'collection',
    path: `/:collection(${config.collection_slug}|classics|basics)`,
    component: () => import('@/views/Categories.vue'),
    props: (route) => ({
      collection: route.params.collection,
    }),
  },
  {
    name: 'products',
    path: `/:collection(${config.collection_slug}|classics|basics)/categories/:category`,
    component: () => import('@/views/Products.vue'),
    props: (route) => ({
      categoryId: +route.params.category,
    }),
  },
  {
    name: 'product',
    path: `/:collection(${config.collection_slug}|classics|basics)/categories/:category/product/:product`,
    component: () => import('@/views/Product.vue'),
    props: (route) => ({
      id: +route.params.product,
    }),
  },
  {
    name: 'search',
    path: '/search/:search',
    component: () => import('@/views/Products.vue'),
    props: (route) => ({
      query: route.params?.search,
    }),
  },
  {
    name: 'order',
    meta: {
      auth: true,
    },
    path: '/order/:variant/:orderId?',
    props: (route) => ({
      variant: +route.params.variant,
      orderId: +route.params.orderId,
    }),
    component: () => import('@/views/ShoeProductOrder.vue'),
  },
  {
    name: 'custom-order',
    meta: {
      auth: true,
    },
    path: '/custom-order/:orderId?',
    props: (route) => ({
      orderId: +route.params.orderId,
    }),
    component: () => import('@/views/ShoeOrder.vue'),
  },
  {
    name: 'reorder',
    meta: {
      auth: true,
    },
    path: '/reorder/:variant/:orderId',
    props: (route) => ({
      variant: +route.params.variant,
      orderId: +route.params.orderId,
      reorder: true,
    }),
    component: () => import('@/views/ShoeProductOrder.vue'),
  },
  {
    name: 'custom-reorder',
    meta: {
      auth: true,
    },
    path: '/custom-reorder/:orderId',
    props: (route) => ({
      orderId: +route.params.orderId,
      reorder: true,
    }),
    component: () => import('@/views/ShoeOrder.vue'),
  },
  {
    name: 'lasts-supplements-foils',
    meta: {
      auth: true,
    },
    path: '/lasts-supplements-foils',
    component: () => import('@/views/LastsSupplementsFoils.vue'),
  },
  {
    name: 'finishing',
    meta: {
      auth: true,
    },
    path: '/finishing',
    component: () => import('@/views/Finishing.vue'),
    beforeEnter: () => config.finishing_enabled,
  },
  {
    name: 'cart',
    meta: {
      auth: true,
    },
    path: '/cart',
    component: () => import('@/views/Cart.vue'),
  },
  {
    name: 'order-history',
    meta: {
      auth: true,
    },
    path: '/order-history',
    component: () => import('@/views/OrderHistory.vue'),
  },
  {
    name: 'order-restore',
    meta: {
      auth: true,
    },
    path: '/order-history/restore/:orderId',
    props: (route) => ({
      restoreId: +route.params.orderId,
    }),
    component: () => import('@/views/OrderHistory.vue'),
  },
];
