const collectionName = process.env.VUE_APP_COLLECTION_NAME ?? 'CMS';

export default {
  image_field: process.env.VUE_APP_IMAGE_FIELD ?? 'url',
  order_image_field: process.env.VUE_APP_ORDER_IMAGE_FIELD ?? 'image_variant_1024',
  attribute_value_field: process.env.VUE_APP_ATTRIBUTE_VALUE_FIELD ?? 'product_template_attribute_value_ids',
  collection_name: collectionName,
  collection_slug: collectionName.toLowerCase(),
  portal_url: process.env.VUE_APP_PORTAL_URL,
  finishing_enabled: process.env.VUE_APP_HANDLE === 'zsm',
};
