export default {
  general: {
    hello: 'Hallo',
    back: 'Zurück',
    left: 'Links',
    right: 'Rechts',
    both: 'Beide',
    side: 'Sagittalebene',
    flank: 'Seite',
    backside: 'Frontalebene',
    height: 'Höhe',
    depth: 'Entlastung',
    thickness: 'Dicke',
    standard: 'Standard',
    position: 'Position',
    material: 'Material',
    material_type: 'Materialtyp',
    clothe: 'Kleiden',
    type: 'Typ',
    search: 'Suche',
    cancel: 'Abbrechen',
    send: 'Senden',
    ok: 'OK',
    continue_anyway: 'Mache trotzdem weiter',
    update: 'Ein Update ist verfügbar.',
    install_update: 'Aktualisieren',
    user: 'Benutzer',
    products: 'Produkte',
    client_info: 'Kundendetails',
    order: 'bestellen',
    edit: 'Bearbeiten',
    coming_soon: 'Bald erhältlich',
    add_file: 'Datei hinzufügen',
    salutation_mr: 'männlich',
    salutation_mrs: 'weiblich',
    terms_and_conditions: 'Allgemeine bedingungen',
    product_terms: 'Produktbedingungen',
    faq: 'FAQ',
    not_available: 'Vorübergehend nicht verfügbar',
    updated_color: 'Farbe aktualisiert',
  },
  error: {
    header_api: 'Fehler bei der Kommunikation mit API',
  },
  account: {
    customer_portal: 'Kundenportal',
    title: 'Konto',
    login: 'Anmeldung',
    signout: 'Ausloggen',
    username: 'Nutzername',
    password: 'Passwort',
    forgotpassword: 'Passwort vergessen',
    logged_in: 'Du bist eingeloggt',
    error: {
      title: 'Ungültiger Login',
      not_found: 'Ungültige Benutzername und Passwort Kombination',
    },
  },
  orders: {
    title: 'Meine Bestellungen',
    synchronise: 'Auftragssynchronisation erzwingen',
    history: 'Bestellverlauf',
    archive: 'Gespeicherte Bestellungen',
    custom: 'Custom',
    history_compatibility_header: 'Bestellung nicht kompatibel',
    history_compatibility_message: 'Diese Bestellung ist mit dieser App-Version nicht kompatibel. Überprüfen Sie, ob die Reihenfolge korrekt ist.',
    no_results: 'Keine Bestellungen gefunden',
  },
  language: {
    title: 'Sprache ändern',
    dutch: 'Nederlands',
    german: 'Deutsch',
    english: 'English',
  },
  form: {
    choose_option: 'Option wählen',
    not_available: 'keine Auswahl',
    input_placeholder: '...',
    type: 'Typ',
    number: 'Nummer',
    errors_heading: 'Das Formular ist unvollständig',
    errors_text: 'Einige Felder wurden nicht vollständig ausgefüllt.',
    success_heading: 'Das Formular ist vollständig',
    success_text: 'Bereit, Ihre Bestellung abzugeben.',
    save: 'Speichern',
    select_ok: 'OK',
    select_cancel: 'Abbrechen',
    undeliverable: '{field} ist nicht lieferbar',
  },
  order: {
    shafts: 'Schaft',
    existing_model: 'Modell wie gehabt',
    select_image: 'Bild auswählen',
    remove_shoe_image: 'Schuhbild entfernen',
    color: 'Farbe',
    all: 'Alle',
    amount: 'Menge',
    variant: 'Variante',
    leather_no: 'Oberleder {no}',
    decoration: 'Dekoration',
    perforation: 'Lochung/Perforation',
    complexity_price: 'Bilddatei in guter Qualität und in Farbe',
    embroidery: 'Stickmuster',
    lasering: 'Laser-Gravur',
    lining: 'Futter',
    vamp_lining: 'Blattfutter',
    offset: 'Versatz',
    heelpart_lining: 'Quartierfutter',
    tongue_label: 'Patienten-ID-Etikett',
    sockshoe: 'Ausführung als Sockenschuh',
    sockshoe_description: 'Unten mit Ercoflex-Innensohle und rauer Lederlaufsohle.',
    lining_vamp_no_stitches: 'Futterleder verklebt / keine Nähte',
    antislip_heel: 'Futterhose',
    liner_shoe: 'Futterschuh',
    vamp_point: 'Derbypunkt',
    main_stitches: 'Montagenähte',
    decoration_stitching: 'Ziernähte',
    quarter_opening: 'Abstand der Schnürung / Offenstand',
    choose_quarter_opening: 'Abstand in mm',
    stitching_yarn: 'Nahtstärke',
    fastening: 'Verschluss {no}',
    upper_height: 'Schafthöhe',
    shaft_height_incl_heel: 'Schafthöhe inklusive der Absatzsprengung',
    leg_measurements: 'Beinmaße',
    padded_shaft: 'Quartierpolster',
    polster: 'Polster',
    polster_upper: 'Quartierpolster',
    polster_upper_left: 'Quartierpolster links',
    polster_upper_right: 'Quartierpolster rechts',
    polstering: 'Polster',
    polstering_material: 'Polster Material',
    polster_tongue: 'Laschenpolster',
    heelcollar_polster: 'Abschlusspolster',
    heelcollar_polster_type: 'Schaftabschluss-Typ',
    tongue: 'Spezifikation / Fixierung Lasche',
    fixation_tongue: 'Lasche',
    supplement_type: 'Bettungs-Typ',
    supplement_stiffening: 'Versteifung',
    supplement_thickness: 'Bettungsstärken',
    detailed_supplement_thickness: 'Details Fußbettung Dicke',
    supplement_retaining_wall_height: 'Stützhöhe',
    supplement_amenity_height: 'Anlagenhöhe',
    detail_drawing: 'Nach Formular',
    thickness_left: 'Stärke links',
    thickness_right: 'Stärke rechts',
    thickness_front: 'Bettungsstärke Spitze',
    bottoming: 'Bodenbau',
    manufacture_method: 'Machart',
    manufacture_method_note: 'Verkürzte Lieferzeit bei CNC gefräst (nur Typ A)',
    supplement_remark: 'Bemerkungen Fußbettungen',
    fillsole: 'Extra Korksohle',
    innersole: 'Brandsohle',
    forefoot_prosthesis: 'Zehenersatz',
    sole_stiffner: 'Sohlenversteifung',
    nose_material: 'Vorderkappen',
    nose_length: 'Vorderkappen Länge',
    counter_stiffening: 'Hinterkappe',
    heel_stiffener: 'Hinterkappen',
    heel_stiffener_variant: 'Hinterkappen Variante',
    heel_stiffener_height: 'Hinterkappen höhe',
    last_annotation: 'Angezeichnet auf Leisten',
    garnish: 'Überstemme aus Leder',
    shaft_up_to_and_including_pins: 'Schaft nur aufzwicken',
    shaft_up_to_supply_material: 'Bodenbaumaterial lose der Bestellung beifügen',
    modeling_extension: 'Optischer Ausgleich (Verlängerung der Schuh-Spitze)',
    boarding: 'Verschalung',
    boarding_front: 'Höhe Verschalung (mm) Vorfuß',
    boarding_back: 'Höhe Verschalung (mm) Rückfuß',
    boarding_front_back: 'Höhe Verschalung (mm) Vorfuß und Rückfuß',
    welt: 'Rahmen',
    all_welts: 'Alle Rahmen',
    mid_sole: 'Zwischensohle',
    no_foot_strike: 'Die Zwischensohle hat keine Abrollfunktion',
    heel_type: 'Absatz-Typ',
    heel_position: 'Position Absatzaufbau',
    heel_position_supplement: 'Höhe (mm) unter der Bettung',
    heel_position_bottoming: 'Höhe (mm) unter dem Oberleder',
    heel_color: 'Absatzfarbe',
    primary_color: 'Absatz Erstfarbe',
    secondary_color: 'Absatz Zweitfarbe',
    heel_shore: 'Shore-Härte',
    heel_height: 'Absatzsprengung',
    heel_width: 'Bettungssbreite Ferse',
    copy_left: 'Wert von links übernehmen',
    heel_rolling: 'Absatz-Rolle',
    heel_buffer: 'Pufferabsatz',
    heel_expansion: 'Absatz verbreiten / verschmälern',
    medial: 'Medial',
    lateral: 'Lateral',
    rolling: 'Abrolltechnik',
    rolling_direction: 'Abrollrichtung',
    rolling_side: 'Abrolltechnik {side}',
    rolling_type: 'Abrolltechnik-Typ',
    rolling_width: 'Bettungssbreite Scheitellinie',
    thickness_toe: 'Spitzenstärke',
    thickness_ball: 'Ballenstärke',
    thickness_ball_lateral: 'Bettungsstärke MTP 5',
    thickness_ball_medial: 'Bettungsstärke MTP 1',
    thickness_heel: 'Fersenstärke',
    rocker_wrapped_in_boarding: 'Abrolltechnik verdeckt durch Verschalung',
    sole: 'Laufsohle',
    toplift: 'Absatzfleck',
    finishing: 'Schuhe finishen',
    cover: 'Abdeckung',
    cover_material: 'Abdeckung Material',
    polster_material: 'Polster Material',
    lasting: 'Schuhe ausleisten',
    executor: 'Ausführen durch',
    laces: 'Schnürsenkel',
    finish_supplement: 'Bezug',
    place_cover: 'Abdeckung platzieren',
    padding_layer: 'Polsterung',
    shaft_remark: 'Bemerkungen Schaft',
    bottoming_remark: 'Bemerkungen für den Bodenbau',
    info: 'Bestellinformationen',
    salutation: 'Anrede',
    attachments: 'Anhänge',
    last_no: 'Leistennummer',
    last_note: 'Leistennummer auf dem Kamm notieren',
    customer_name: 'Ihre Auftragsnummer',
    confirm_leave_header: 'Änderungen abbrechen?',
    confirm_leave_message: 'Möchten Sie die Bestellseite wirklich verlassen? Eingegebene Daten werden nicht gespeichert.',
    sent_toast: 'Bestellung wird gesendet',
    number: 'Bestellung',
    date: 'Daten',
    draft: 'Angebot',
    sale: 'Kundenauftrag',
    sent: 'Angebot gesendet',
    done: 'Verstopft',
    cancel: 'Abgesagt',
    reorder: 'Nachbestellen',
    edit: 'Bearbeiten',
    form: 'Formular',
    velcro_extra_length: 'Extra lange Kletten',
    last: 'Leisten',
    has_last: 'Hast liest',
    supplements: 'Fußbettungen',
    foil_shoe: 'Probeschuhe',
    foil_shoe_height: 'Höhe (inkl. Bettung)',
    foil_shoe_remark: 'Bemerkungen Probeschuhe',
    copy_existing_last: 'Mitgelieferten Leisten kopieren',
    deliver: 'Lieferung',
    deliver_note: 'Welche Dokumente senden oder fügen Sie hinzu?',
    cast: 'Casthülle',
    blueprint: 'Trittspur (bemaßt in Länge und Breite als Dokument-Scan)',
    scan: '3D-Fußscan (Schein-Scan-App)',
    modeled_file: 'Modellierte STL-Datei',
    scan_upload: '3D-Fußscan (STL Datei erforderlich)',
    last_type: 'Leisten Typ',
    last_same_length: 'Leisten gleiche Länge',
    encore: 'Leistenspitze',
    encore_division: 'Zugabe {side}',
    leg_position: 'Unterschenkelstellung',
    fabric_edge: 'Leistenkante',
    nose_shape: 'Spitzenform',
    last_remark: 'Bemerkungen Leisten',
    sizing: 'Maße',
    leg_sizes: 'Umfangsmaße Bein',
    height_measurement: 'Höhe',
    circumferences: 'Umfangsmaße Fuß',
    circumferences_no: 'Umfangsmaße Fuß {no}',
    foot_measurements: 'Fußsohlenplastik / Topologie',
    position_toes: 'Entlastung Zehen',
    height_toes: 'Höhenmaß der Zehen',
    depth_toes: 'Entlastung (plantar)',
    mtp_joints: 'Entlastung MTP Gelenke',
    depth_position: 'MTP {no}',
    lateral_and_medial_gel: 'laterales Längsgewölbe und mediale Längsgewölbestütze',
    medial_gel: 'mediales Längsgewölbe',
    lateral_gel: 'laterales Längsgewölbe',
    imprint_base_back: 'Impressum Rückseite Base V',
    forefoot_support: 'Retrokabitale Pelottierung',
    forefoot_support_instructions: 'Nachtrag abmelden',
    t_pad: 'Pelotte (MFK 2-4)',
    transversal: 'Stufenentlastung (MFK 1-5)',
    forefoot_and_heel_bone: 'Pronation und Supination',
    pronation_front: 'Pronation Vorfuß',
    pronation_back: 'Pronation Rückfuß',
    suppination_front: 'Supination Vorfuß',
    suppination_back: 'Supination Rückfuß',
    lasts_supplements_foils: 'Leisten, Fußbettungen und Probeschuhe',
    finish: 'Schuhe finishen (ausleisten, bezogen und schnüren)',
    classics: 'Classics',
    flea: 'Temporär',
    orthotics: 'Orthesen',
    functional: 'Funktionell',
    heel_instep: 'Fersenmaß',
    high_instep: 'Spannmaß',
    low_instep: 'Vorspannmaß',
    ball: 'Ballenmaß',
    toe_jump: 'Spitzensprengung',
    heel_lift: 'Fersensprengung',
    heel_thickness: 'Bettungsstärke Ferse',
    toe_no: 'Zehe {no}',
    dimensions: 'Maße',
    order_status: 'Bestellstatus',
    confirm_osa_header: 'Maximale Anzahl von OSA\'s im Einkaufswagen',
    confirm_osa_message: 'Möchten Sie den Warenkorb leeren und eine neue Bestellung starten?',
    confirm_osa_button_ok: 'Warenkorb leeren',
    leg_cover: 'Fall',
    trial_shoe: 'Probeschuh',
    bath_shoe: 'Badeschuh',
    slipper: 'Slipper',
    basics: 'Basics',
    sock_shoe: 'Sockenschuh',
    kind: 'Art',
    changed_color_remark: '{color} ist von Farbe geändert',
  },
  cart: {
    title: 'Warenkorb',
    order: 'Sie bestellen für',
    empty: 'Ihr Warenkorb ist leer',
    added_header: 'Produkt hinzugefügt',
    added_subHeader: 'für Kommision {customer}',
    added_text: 'Möchten Sie für diesen Kunden ein weiteres Produkt bestellen?',
    added_continue_shopping: 'Weiteres Produkt bestellen',
    add_product: 'Product hinzufügen',
    added_finish_order: 'Zum Einkaufskorb',
    last_supplement_or_foil: 'Leisten, Fußbettungen und Probeschuhe',
    finish: 'Dekorieren',
    custom: 'Custom',
    send_header: 'Bestellung senden?',
    send_text: 'Bitte beachten Sie: Prüfen Sie sorgfältig, ob die Bestellung korrekt ausgefüllt wurde.',
    send_ok: 'Ja, Bestellung absenden',
    submitting_order: 'Auftrag bearbeiten',
    submitting_line: 'Verarbeitungslinie {index} von {total}',
    submitting_attachment: 'Verarbeitung des Anhangs {index} von {total}',
    new_order: 'Neue Bestellung',
    confirm_empty_header: 'Sind Sie sicher, dass Sie den Kundendetails und Warenkorb leeren möchten?',
    confirm_empty_button_ok: 'Von vorn anfangen',
    edit_client_info: 'Kundendetails bearbeiten',
    production_order: 'Ziehen Sie die Produkte, um die Produktionsreihenfolge anzupassen.\nDie Bestellung wird von oben nach unten verarbeitet.',
    verify_order: 'Überprüfen Sie alle Bestellzeilen über das Zahnradsymbol.',
    verify_order_label: 'Überprüfung der Bestellposition',
  },
};
