import { reactive } from 'vue';
import { createI18n } from 'vue-i18n';
import { setLocale as validationLocale } from '@vee-validate/i18n';
import messagesEN from '@/assets/i18n/en/messages';
import messagesNL from '@/assets/i18n/nl/messages';
import messagesDE from '@/assets/i18n/de/messages';

export const messages = {
  en: messagesEN,
  nl: messagesNL,
  de: messagesDE,
};

export const defaultLanguage = process.env.VUE_APP_DEFAULT_LANGUAGE;
const preferred = navigator.language.split('-')[0];
const lang = messages[preferred] !== undefined ? preferred : defaultLanguage;

const i18n = createI18n({
  locale: lang,
  fallbackLocale: defaultLanguage,
  silentFallbackWarn: true,
  messages,
});

export const languages = [
  { locale: 'en_US', lang: 'en', name: i18n.global.t('language.english') },
  { locale: 'nl_NL', lang: 'nl', name: i18n.global.t('language.dutch') },
  { locale: 'de_DE', lang: 'de', name: i18n.global.t('language.german') },
];

export const localeByLang = (l) => languages.find((i) => i.lang === l || i.locale === l);

export const context = reactive({
  locale: localeByLang(lang).locale,
});

export function changeLocale(setting) {
  const language = localeByLang(setting);
  i18n.global.locale = language.lang;
  context.locale = language.locale;
  validationLocale(language.lang);
  document.documentElement.setAttribute('lang', language.lang);
}

export default i18n;
