import { JSONRPCClient } from 'json-rpc-2.0';
import { context } from '@/plugins/i18n';
import EventBus from '@/eventBus';

const requestHandler = async (apiUrl, client, req) => {
  const response = await fetch(`${apiUrl}/jsonrpc`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(req),
  });

  if (response?.status === 200) {
    let data;
    try {
      data = await response.json();
    } catch (error) {
      console.debug('failed to parse response for request', req.params?.args);
      throw (error);
    }

    if (data.error !== undefined) {
      if (data.error.data.name === 'odoo.exceptions.AccessDenied') {
        EventBus.emit('authentication_error');
      }
      throw new Error(data.error.data?.message || data.error.message);
    } else {
      client.receive(data);
    }
  } else {
    throw new Error(response?.statusText);
  }
};

function addMachineUserAuth(req) {
  req.params.args = [
    process.env.VUE_APP_API_PRODUCTS_DB,
    process.env.VUE_APP_API_PRODUCTS_USER,
    process.env.VUE_APP_API_PRODUCTS_PASSWORD,
    ...req.params.args,
  ];
  return req;
}

const odooClient = new JSONRPCClient(async (req) => requestHandler(
  process.env.VUE_APP_API_URL,
  odooClient,
  req,
));

export const withLocale = (kwargs = {}) => {
  const params = { ...kwargs };
  params.context = kwargs.context || {};
  params.context.lang = context.locale;
  return params;
};

export default odooClient;

export const odooProductsClient = new JSONRPCClient(async (req) => requestHandler(
  process.env.VUE_APP_API_PRODUCTS_URL,
  odooProductsClient,
  addMachineUserAuth(req),
));
