import { createApp } from 'vue';
import './registerServiceWorker';

/* Import much-used global components */
import {
  IonBackdrop,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonThumbnail,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItemGroup,
  IonItem,
  IonLabel,
  IonNote,
  IonText,
  IonIcon,
  IonSkeletonText,
  IonSpinner,
} from '@ionic/vue';

import * as Sentry from '@sentry/vue';
import ionic from '@/plugins/ionic';
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';
import konva from '@/plugins/konva';
import '@/plugins/vee-validate';
import { auth } from '@/auth';
import config from '@/config';

import App from '@/App.vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/assets/css/variables.css';

/* Global style */
import '@/assets/css/style.css';

const app = createApp(App)
  .use(ionic, {
    mode: 'ios',
  })
  .use(i18n)
  .use(konva);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_NAME,
    release: `${process.env.VUE_APP_HANDLE}@${process.env.PACKAGE_VERSION}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    trackComponents: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(router);

app.component('IonBackdrop', IonBackdrop);
app.component('IonPage', IonPage);
app.component('IonHeader', IonHeader);
app.component('IonToolbar', IonToolbar);
app.component('IonTitle', IonTitle);
app.component('IonThumbnail', IonThumbnail);
app.component('IonContent', IonContent);
app.component('IonGrid', IonGrid);
app.component('IonRow', IonRow);
app.component('IonCol', IonCol);
app.component('IonButton', IonButton);
app.component('IonCard', IonCard);
app.component('IonCardHeader', IonCardHeader);
app.component('IonCardTitle', IonCardTitle);
app.component('IonCardContent', IonCardContent);
app.component('IonList', IonList);
app.component('IonItem', IonItem);
app.component('IonItemGroup', IonItemGroup);
app.component('IonLabel', IonLabel);
app.component('IonNote', IonNote);
app.component('IonText', IonText);
app.component('IonIcon', IonIcon);
app.component('IonSkeletonText', IonSkeletonText);
app.component('IonSpinner', IonSpinner);

Object.assign(app.config.globalProperties, config);

router.isReady().then(() => {
  app.mount('#app');
});

router.beforeEach(async (to, from, next) => {
  const user = await auth.getUser();
  if (!to.meta.auth || user.is_user) {
    next();
  } else {
    next({ name: 'dashboard' });
  }
});
