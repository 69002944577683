import odooClient from '@/odooClient';
import store, { storage } from '@/store';

const defaultAuth = { ...store.auth };

export const auth = {
  /**
   * Get user.
   *
   * @return {Object}
   */
  async getUser() {
    const user = await (await storage).get('user');

    // insert storage data into store
    if (user) {
      Object.assign(store.auth, {
        name: user.name,
        password: user.password,
        id: user.id,
        partner_id: user.partner_id,
        lang: user.lang,
        is_user: true,
      });
    }

    return store.auth;
  },
  /**
   * Set user from odooClient.
   *
   * @param {String} username
   * @param {String} password
   *
   * @return {Mixed}
   */
  async setUser(username, password) {
    const userId = await odooClient.request('call', {
      service: 'common',
      method: 'authenticate',
      args: [
        process.env.VUE_APP_API_DB,
        username,
        password,
        { name: 'PWA' },
      ],
    });

    if (userId) {
      return this.refreshUserData(userId, password);
    }

    return false;
  },
  /**
   * Refresh user data.
   *
   * @param {Number} userId
   * @param {String} password
   *
   * @return {Promise}
   */
  async refreshUserData(userId, password) {
    let user;
    try {
      user = await this.fetchUser(userId, password);
    } catch (e) {
      await this.clearStorage();
      window.location.assign('/');
      return false;
    }

    const state = {
      id: userId,
      is_user: true,
      name: user[0].display_name,
      partner_id: user[0].partner_id[0],
      partner_user_id: null,
      lang: user[0].lang,
      password,
    };

    if (user[0].partner_id.length) {
      const partner = await this.fetchPartner(
        userId,
        user[0].partner_id[0],
        password,
        { fields: ['user_id'] },
      );
      state.partner_user_id = partner[0]?.user_id[0];
    }

    // update both store and storage
    Object.assign(store.auth, state);
    return (await storage).set('user', state);
  },
  /**
   * Fetch user.
   *
   * @param {Number} userId
   * @param {String} password
   * @param {Array} fields
   *
   * @return {Promise}
   */
  async fetchUser(userId, password, fields = []) {
    const args = [
      process.env.VUE_APP_API_DB,
      userId,
      password,
      'res.users',
      'read',
      [userId],
      { fields: [...fields, 'display_name', 'partner_id', 'commercial_partner_id', 'email', 'login', 'lang'] },
    ];

    return odooClient.request('call_kw', {
      service: 'object',
      method: 'execute_kw',
      args,
    });
  },
  /**
   * Fetch partner.
   *
   * @param {Number} userId
   * @param {Number} partnerId
   * @param {String} password
   * @param {Object} context
   *
   * @return {Promise}
   */
  async fetchPartner(userId, partnerId, password, context) {
    const args = [
      process.env.VUE_APP_API_DB,
      userId,
      password,
      'res.partner',
      'read',
      [partnerId],
      context,
    ];

    return odooClient.request('call_kw', {
      service: 'object',
      method: 'execute_kw',
      args,
    });
  },
  /**
   * Clear storage.
   *
   * @return {Promise}
   */
  async clearStorage() {
    // fallback to public api user authentication
    Object.assign(store.auth, defaultAuth);

    return (await storage).clear();
  },
};

export const withAuth = async (args = []) => {
  const user = await auth.getUser();
  return ([
    process.env.VUE_APP_API_DB,
    user.id,
    user.password,
    ...args,
  ]);
};

export default auth;
