<template>
  <ion-app :key="$i18n.locale">
    <ion-menu
      side="end"
      type="overlay"
      content-id="main"
      class="menu"
    >
      <ion-header>
        <ion-toolbar color="primary">
          <ion-buttons slot="start">
            <ion-button @click="menuController.close()">
              <ion-icon
                slot="icon-only"
                :icon="closeOutline"
              />
            </ion-button>
          </ion-buttons>
          <ion-title v-if="user.is_user">
            {{ $t('general.hello') }} {{ user.name }}!
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content color="primary">
        <ion-accordion-group>
          <ion-accordion
            value="dashboard"
            :toggle-icon="arrowForward"
            readonly
            @click="dashboard"
          >
            <ion-item slot="header">
              <ion-icon
                slot="start"
                :icon="bagHandleOutline"
              />
              <ion-label>{{ $t('general.products') }}</ion-label>
            </ion-item>
          </ion-accordion>
          <ion-accordion value="account">
            <ion-item slot="header">
              <ion-icon
                slot="start"
                :icon="personOutline"
              />
              <ion-label>{{ $t('account.title') }}</ion-label>
            </ion-item>
            <ion-list
              slot="content"
              lines="none"
            >
              <template v-if="(app_handle === 'zsm')">
                <a href="https://www.zsmorthopedics.com/nieuwefeatures/" target="_blank" rel="noopener noreferrer">
                  <ion-item>
                    <ion-icon
                      slot="start"
                      :icon="informationCircleOutline"
                    />
                    <IonLabel>{{ $t('general.faq') }}</IonLabel>
                  </ion-item>
                </a>
                <a href="https://www.zsmorthopedics.com/wp-content/uploads/2023/05/Productvoorwaarden-15-mei-2023.pdf" target="_blank" rel="noopener noreferrer">
                  <ion-item>
                    <ion-icon
                      slot="start"
                      :icon="informationCircleOutline"
                    />
                    <IonLabel>{{ $t('general.product_terms') }}</IonLabel>
                  </ion-item>
                </a>
                <a href="https://www.zsmorthopedics.com/wp-content/uploads/2021/05/algemene-voorwaarden-zsm-2021-05-25.pdf" target="_blank" rel="noopener noreferrer">
                  <ion-item>
                    <ion-icon
                      slot="start"
                      :icon="informationCircleOutline"
                    />
                    <IonLabel>{{ $t('general.terms_and_conditions') }}</IonLabel>
                  </ion-item>
                </a>
              </template>
              <ion-item
                v-if="user.is_user"
                :detail="false"
                button
                @click="logout()"
              >
                <ion-icon
                  slot="start"
                  :icon="logOutOutline"
                />
                <ion-label>{{ $t('account.signout') }}</ion-label>
              </ion-item>
              <template v-else>
                <ion-item
                  :detail="false"
                  button
                  @click="openLogin(onLogin)"
                >
                  <ion-icon
                    slot="start"
                    :icon="logInOutline"
                  />
                  <ion-label>{{ $t('account.login') }}</ion-label>
                </ion-item>
                <ion-item
                  :detail="false"
                  button
                  :href="passwordResetLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ion-icon
                    slot="start"
                    :icon="lockOpenOutline"
                  />
                  <ion-label>{{ $t('account.forgotpassword') }}</ion-label>
                </ion-item>
              </template>
            </ion-list>
          </ion-accordion>
          <ion-accordion
            v-if="user.is_user"
            value="order"
            :toggle-icon="arrowForward"
            @click="orderHistory"
          >
            <ion-item slot="header">
              <ion-icon
                slot="start"
                :icon="clipboardOutline"
              />
              <ion-label>{{ $t('orders.history') }}</ion-label>
            </ion-item>
          </ion-accordion>
          <ion-accordion value="language">
            <ion-item slot="header">
              <ion-icon
                slot="start"
                :icon="settingsOutline"
              />
              <ion-label>{{ $t('language.title') }}</ion-label>
            </ion-item>
            <ion-list
              slot="content"
              lines="none"
            >
              <ion-item
                v-for="language in languages"
                :key="`language-${language.locale}`"
                :detail="false"
                button
                @click="changeLanguage(language.lang)"
              >
                <ion-icon
                  slot="start"
                  :icon="languageOutline"
                />
                <ion-label>{{ language.name }}</ion-label>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
        <div class="ion-margin-top ion-text-center">
          v{{ package_version }}
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet
      v-if="ready"
      id="main"
    />
    <div
      v-else
      id="main"
    />
  </ion-app>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonApp,
  IonMenu,
  IonButtons,
  IonAccordionGroup,
  IonAccordion,
  IonRouterOutlet,
  alertController,
  menuController,
  toastController,
  IonLabel,
} from '@ionic/vue';
import {
  closeOutline,
  personOutline,
  logInOutline,
  logOutOutline,
  lockOpenOutline,
  clipboardOutline,
  cloudUploadOutline,
  timeOutline,
  archiveOutline,
  settingsOutline,
  languageOutline,
  bagHandleOutline,
  cameraOutline,
  informationCircleOutline,
  arrowForward,
} from 'ionicons/icons';
import { Storage } from '@ionic/storage/dist/esm';
import i18n, { changeLocale, localeByLang, languages } from '@/plugins/i18n';
import UserMixin from '@/mixins/UserMixin';
import EventBus from '@/eventBus';
import { auth } from '@/auth';

export default defineComponent({
  components: {
    IonApp,
    IonMenu,
    IonButtons,
    IonAccordionGroup,
    IonAccordion,
    IonRouterOutlet,
    IonLabel,
  },
  mixins: [UserMixin],
  setup() {
    return {
      languages,
      closeOutline,
      personOutline,
      logInOutline,
      logOutOutline,
      lockOpenOutline,
      clipboardOutline,
      cloudUploadOutline,
      timeOutline,
      archiveOutline,
      settingsOutline,
      languageOutline,
      bagHandleOutline,
      cameraOutline,
      informationCircleOutline,
      arrowForward,
      menuController,
    };
  },
  data() {
    return {
      passwordResetLink: process.env.VUE_APP_PASSWORD_RESET_LINK,
      ready: false,
    };
  },
  computed: {
    package_version() {
      return process.env.PACKAGE_VERSION;
    },
    app_handle() {
      return process.env.VUE_APP_HANDLE;
    },
  },
  async mounted() {
    const storage = await this.getStorage();
    const preferredLocale = await storage.get('preferred_locale');
    if (preferredLocale) {
      changeLocale(preferredLocale);
    }
  },
  async created() {
    this.refreshUser();
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.refreshUser();
      }
    }, false);

    EventBus.on('authentication_error', async () => {
      await this.logout();
      window.location.assign('/');
    });

    EventBus.on('sw', async (state) => {
      if (state === 'updated') {
        const alert = await alertController
          .create({
            header: i18n.global.t('general.update'),
            buttons: [
              {
                text: i18n.global.t('general.cancel'),
                role: 'cancel',
              },
              {
                text: i18n.global.t('general.install_update'),
                /* c8 ignore next 5 */
                handler: async () => {
                  const registrations = await navigator.serviceWorker.getRegistrations();
                  await Promise.all(registrations.map((r) => r.unregister()));
                  window.location.reload(true);
                },
              },
            ],
          });
        await alert.present();
      }
    });

    /* c8 ignore start */
    if (process.env.VUE_APP_HANDLE === 'schein') {
      // Set schein theme
      const root = document.documentElement;
      root.style.setProperty('--ion-color-primary', '#fe5000');
      root.style.setProperty('--ion-color-primary-shade', '#e04600');
      root.style.setProperty('--ion-color-secondary', '#abbac3');
      root.style.setProperty('--ion-color-secondary-contrast', '#000000');
    }
    /* c8 ignore end */
  },
  methods: {
    /**
     * Change language.
     *
     * @param {String} locale
     */
    async changeLanguage(locale) {
      menuController.close();
      const storage = await this.getStorage();
      storage.set('preferred_locale', locale);
      changeLocale(locale);
    },
    /**
     * Dashboard.
     */
    dashboard() {
      menuController.close();
      this.$router.push({ name: 'dashboard' });
    },
    /**
     * Custom order.
     */
    customOrder() {
      menuController.close();
      this.$router.push({ name: 'custom-order' });
    },
    /**
     * Order history.
     */
    orderHistory() {
      menuController.close();
      this.$router.push({ name: 'order-history' });
    },
    /**
     * On login.
     *
     * @param {Object} user
     */
    async onLogin(user) {
      changeLocale(user.lang);
      const toast = await toastController
        .create({
          header: this.$t('account.logged_in', localeByLang(user.lang).lang),
          position: 'bottom',
          color: 'success',
          duration: 5000,
        });
      await toast.present();
    },
    /**
     * Refresh user auth.
     */
    async refreshUser() {
      const user = await auth.getUser();
      const storage = await this.getStorage();
      const preferredLocale = await storage.get('preferred_locale');

      this.ready = true;
      if (user.is_user) {
        auth.refreshUserData(user.id, user.password);
        if (!preferredLocale) {
          changeLocale(user.lang);
        }
      }
    },
    /**
     * Get storage.
     *
     * @returns {Promise}
     */
    async getStorage() {
      const storage = new Storage();
      return storage.create();
    },
  },
});
</script>

<style scoped>
.menu {
  --width: 300px;
  --ion-background: var(--ion-color-primary);

  @media screen and (min-width: 768px) {
    --width: 500px;
  }

  ion-accordion {
    --ion-item-background: var(--ion-color-primary);
  }

  ion-accordion.accordion-expanding ion-item[slot="header"],
  ion-accordion.accordion-expanded ion-item[slot="header"] {
    ion-label {
      --color: var(--ion-color-secondary);
    }
  }

  ion-accordion ion-list[slot="content"] {
    --ion-item-background: var(--ion-color-primary-shade);
  }

  ion-item {
    --color: var(--ion-color-primary-contrast);
  }

  a {
    text-decoration: none;
  }
}
</style>

<style>
@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal {
    --height: 95vh;
  }
}
</style>
