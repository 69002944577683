import { configure, defineRule } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import i18n from '@/plugins/i18n';
import {
  // eslint-disable-next-line camelcase
  required, numeric, min_value, max_value, one_of, email,
} from '@vee-validate/rules';
import de from '@vee-validate/i18n/dist/locale/de.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';

defineRule('required', required);
defineRule('numeric', numeric);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('one_of', one_of);
defineRule('email', email);
defineRule('step', (value, [step]) => Math.abs(value % step) === 0);
defineRule('is_object', (value) => typeof value === 'object');
defineRule('is_outdated', (o) => !o);
defineRule('is_undeliverable', (a, [u, field]) => {
  if (u) {
    return i18n.global.t('form.undeliverable', { field });
  }

  return !u;
});

configure({
  generateMessage: localize({
    de,
    nl,
  }),
});
