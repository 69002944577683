<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon
            slot="icon-only"
            :icon="close"
          />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-item
      lines="none"
      class="ion-margin-top ion-margin-end"
    >
      <ion-label position="stacked">
        {{ $t('account.username') }}
      </ion-label>
      <Field
        v-slot="{ field, errors }"
        v-model="account.username"
        rules="required"
        :name="$t('account.username')"
      >
        <ion-input
          :aria-label="$t('account.username')"
          class="ion-margin-top"
          v-bind="field"
          inputmode="email"
          autocomplete="username"
          :color="errors.length ? 'danger' : undefined"
          :placeholder="$t('form.input_placeholder')"
        />
      </Field>
    </ion-item>
    <ion-item
      lines="none"
      class="ion-margin-top ion-margin-end"
    >
      <ion-label position="stacked">
        {{ $t('account.password') }}
      </ion-label>
      <Field
        v-slot="{ field, errors }"
        v-model="account.password"
        rules="required"
        :name="$t('account.password')"
      >
        <ion-input
          :aria-label="$t('account.password')"
          v-bind="field"
          autocomplete="current-password"
          :color="errors.length ? 'danger' : undefined"
          :placeholder="$t('form.input_placeholder')"
          type="password"
        />
      </Field>
    </ion-item>
    <ion-button
      expand="block"
      @click="login(account)"
    >
      {{ $t('account.login') }}
    </ion-button>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonInput,
  modalController,
  toastController,
} from '@ionic/vue';
import { close } from 'ionicons/icons';
import { Field } from 'vee-validate';
import { auth } from '@/auth';

export default defineComponent({
  components: {
    Field,
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonInput,
    IonToolbar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    onLogin: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      close,
    };
  },
  data() {
    return {
      account: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    /**
     * Close modal.
     */
    async closeModal() {
      await modalController.dismiss();
    },
    /**
     * Login user.
     *
     * @param {Object} login
     */
    async login(login) {
      const user = await auth.setUser(login.username, login.password);
      if (user) {
        this.onLogin(user);
        this.closeModal();
      } else {
        const toast = await toastController
          .create({
            header: this.$t('account.error.title'),
            message: this.$t('account.error.not_found'),
            position: 'bottom',
            color: 'danger',
            duration: 5000,
          });
        await toast.present();
      }
    },
  },
});
</script>

<style scoped>
ion-content {
  ion-input {
    --padding-top: 12px !important;
    --padding-end: 12px !important;
    --padding-bottom: 12px !important;
    --padding-start: 12px !important;
  }

  ion-button {
    margin: 20px 26px 20px 20px;

    --border-radius: 5px;
  }
}
</style>
